
import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Cities({ props }) {

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 0 },
            items: 1,
        },
    }

    console.log("gggggggggg", props);

    return (
        <>
            <div className="">
                <div className="catachup_box">
                    <Carousel
                        responsive={responsive}
                        swipeable={false}
                        draggable={false}
                        arrows={false}
                        showDots={false}
                        autoPlay={true}
                        rewind={true}
                        autoPlaySpeed={2000}
                        renderArrowsWhenDisabled="true"
                    >

                        {
                            props ? (props?.map((op, i) => {
                                return (
                                    <div className="img_box position-relative" key={i}>
                                        <img src={op?.image} alt={op?.title} />
                                        <h5>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none" className="me-2">
                                                <path d="M8 10C8.55 10 9.021 9.804 9.413 9.412C9.80433 9.02067 10 8.55 10 8C10 7.45 9.80433 6.979 9.413 6.587C9.021 6.19567 8.55 6 8 6C7.45 6 6.97933 6.19567 6.588 6.587C6.196 6.979 6 7.45 6 8C6 8.55 6.196 9.02067 6.588 9.412C6.97933 9.804 7.45 10 8 10ZM8 20C5.31667 17.7167 3.31267 15.5957 1.988 13.637C0.662667 11.679 0 9.86667 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.021 0.741667 5.88333 0 8 0C10.1167 0 11.979 0.741667 13.587 2.225C15.1957 3.70833 16 5.7 16 8.2C16 9.86667 15.3377 11.679 14.013 13.637C12.6877 15.5957 10.6833 17.7167 8 20Z" fill="#fff " />
                                            </svg>
                                            {op?.name}</h5>
                                    </div>

                                )
                            }))
                                : <div className="placeholder_div position-relative">
                                    <div className="placeholder_box"></div>
                                </div>
                        }
                    </Carousel>
                    <h4 className="mt-3">Cities</h4>
                    <small>Discover new city vibes and plan ahead for your next night out</small>
                </div>
            </div>
        </>


    )
}



