import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Hero extends Component {
  render() {
    return (
      <section className="hero_section position-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-5 col-lg-6">
              <div className="center_content">
                <h5>Live nightlife Experience</h5>
                <h1 className="mt-4">
                  Discover your vibe <br /> with CatchApp
                </h1>
                <p>
                  Save time and money by knowing what’s happening in different venues in real-time. Make informed decisions on how best to enjoy nightlife experiences in different cities.
                </p>
                <div className="mt-4">
                  <a href="https://apps.apple.com/us/app/catch-app/id1487117177?ls=1" target="_blank" className="border-0 p-0 me-3">
                    <img
                      src={
                        require("../../Assets/images/app_store.svg").default
                      }
                      alt="app store"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.app.catchclientapp" target="_blank" className="border-0 p-0 bg-transparent">
                    <img
                      src={require("../../Assets/images/google_play.png")}
                      alt="google play"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="m-auto col-md-6" style={{ "position": "unset" }}>
              <img
                src={require("../../Assets/images/hero.png")}
                alt="img"
                className="hero_img d-none d-xl-block d-xxl-block"
              />
              <img
                src={require("../../Assets/images/mob_hero.png")}
                alt="img"
                className="md_hero_img d-none d-md-block d-xl-none d-xxl-none"
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
