import React, { Component } from "react";
import { Link } from 'react-router-dom';
export default class Footer extends Component {
  render() {
    return (

      // Old Footer 

      // <div>
      //   <div className={"container"}>
      //     <div className="row">
      //       <div className="col-12 copyright text-center">
      //         Copyright © 2020 <span className="color-footer">CatchApp</span> |
      //         All Rights Reserved{" "}
      //       </div>
      //     </div>
      //   </div>
      // </div>

     // New Footer

      <footer>
          <div className="container">
              <div className="row">
                  <div className="col-lg-3 col-m-3 col-sm-4 col-12">
                      <img src={require('../../Assets/images/footer_logo.png')} alt="logo" />
                      <p className='mt-4 text-left'>Join our community and Live stream nightlife experiences, connect and make memories.</p>
                      <div className="social_links">
                        <a href="https://instagram.com/catchapp.live_?igshid=YmMyMTA2M2Y=" target="_blank">
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a href="https://www.twitter.com/catchapp_live" target="_blank">
                            <i className="fa fa-twitter"></i>
                        </a>
                        <a href="https://www.youtube.com/@catchappllc" target="_blank">
                            <i className="fa fa-youtube"></i>
                        </a>
                      </div>
                  </div>
                  <div className="col-lg-3 col-m-3 col-sm-4 col-6">
                      <h5>Company</h5>
                      <ul>
                          <li>
                              <Link to="/about-us">About us</Link>
                          </li>
                          <li>
                                <a href="mailto:Support@catchapp.live">Support</a>
                          </li>
                          <li>
                              <Link to="/">Team</Link>
                          </li>
                          <li>
                              <Link to="/">Resources</Link>
                          </li>
                          <li>
                              <Link to="/">FAQ</Link>
                          </li>
                          <li>
                              <Link to="/">Articles</Link>
                          </li>
                          <li>
                              <Link to="/">Legal</Link>
                          </li>
                      </ul>
                  </div>
                  <div className="col-lg-3 col-m-3 col-sm-4 col-6">
                      <h5>Apply</h5>
                      <ul>
                          <li>
                              <Link to="/dj-login">DJ</Link>
                          </li>
                          <li>
                              <Link to="/">Promoter</Link>
                          </li>
                          <li>
                              <Link to="/">Venue</Link>
                          </li>
                          <li>
                              <Link to="/">Event</Link>
                          </li>
                      </ul>
                  </div>
                  <div className="col-lg-3 col-m-3 col-sm-4 col-6">
                      <h5>Download</h5>
                      <div className="border-0 p-0 bg-transparent mb-3">
                          <a href="https://apps.apple.com/us/app/catch-app/id1487117177?ls=1" target="_blank" >
                              <img src={require('../../Assets/images/app_store.svg').default} alt="app store" />
                          </a>
                      </div>
                      <div className='border-0 p-0 bg-transparent'>
                        <a href="https://play.google.com/store/apps/details?id=com.app.catchclientapp" target="_blank" >
                          <img src={require('../../Assets/images/google_play.png')} alt="google play" />
                        </a>
                      </div>

                  </div>
                  <div className="col-lg-12">
                    <div className='copy_right'>Copyright h©️ 2023.  CatchApp hub LLC</div>
                      {/* <div className='copy_right'>
                          © 2012 – 2022@Catchappservices.com
                      </div> */}
                  </div>
              </div>
          </div>
      </footer>
    );
  }
}
