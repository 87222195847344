import React, { Component } from 'react'
import Cities from './Cities';
import Music from './Music';
import Dj from './Dj';
import Events from './Events';

class Options extends Component {

  constructor({ props }) {
    super({ props });
    this.state = {
      options: [
        {
          title: "Cities",
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          description:
            "Discover new city vibes and plan ahead for your next night out",
        },
        {
          title: "Music",
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          description:
            "Listen to different venues music and decide what suits you moods in real-time",
        },
        {
          title: "DJ's",
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          description:
            "Get to know which venues your favorite Djs are planning or planning to play",
        },
        {
          title: "Events",
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          description:
            "Live stream different events from different cities ",
        },
      ],

    }
    console.log("props---->", this.props);
  }

  render() {
    return (
      <section className="catch_up_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center mb-4">
                <h2 className="sub_heading">Explore your options </h2>
                <p>
                At CatchApp every moment counts, let’s revolutionize
                 your party scene with our cutting-edge technology
                </p>
              </div>
            </div>
            {/* {this.state.options.map((op, index) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-3"
                key={index}
              >
                <div className="catachup_box">
                  <div className="img_box">
                    <img src={op.img} alt={op.title} />
                  </div>
                  <h4>{op.title}</h4>
                  <small>{op.description}</small>
                </div>
              </div>
            ))} */}

            <div
              className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-3">
              <Cities props={this.props?.optionsData?.cities}/>

            </div>

            <div
              className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-3">
              <Music props={this.props?.optionsData?.music} />
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-3">
              <Dj props={this.props?.optionsData?.dj} />
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-3">
              <Events props={this.props?.optionsData?.events} />
            </div>


          </div>
        </div>
      </section>
    )
  }
}


export default Options;