import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { logout } from "../../Utils/services.js";

class Header extends Component {
  constructor(props) {
    super(props);
    const userType = localStorage.getItem("user-type");
    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    this.state = {
      userType: userType ? userType : "",
      showMe: false,
      isLoggedIn: isLoggedIn ? isLoggedIn : 0,
    };
  }

  handleRedirect = () => {
    if (this.state.userType == "dj") {
      return <div>{this.props.history.push(`/dashboard`)}</div>;
    }
    if (this.state.userType == "user") {
      return <div>{this.props.history.push(`/`)}</div>;
    }
  };

  redirectToProfile = () => {
    return <div>{this.props.history.push(`/profile`)}</div>;
  };

  redirectToLogin = () => {
    return <div>{this.props.history.push(`/user-login`)}</div>;
  };

  logoutMe = () => {
    logout();
    setTimeout(() => {
      this.props.history.push("/");
    }, 0);
  };

  redirectToDj = () => {
    return <div>{this.props.history.push(`/dj-login`)}</div>;
  };

  redirectToAbout = () => {
    return <div>{this.props.history.push(`/about-us`)}</div>;
  };

  render() {
    const userType = localStorage.getItem("user-type");
    const { userProfile, userName } = this.props;
    return (
      // Old Header

      // <div>
      //   <nav className="navbar navbar-expand-lg navbar-light">
      //     <button
      //       className="navbar-toggler"
      //       type="button"
      //       data-toggle="collapse"
      //       data-target="#navbarTogglerDemo02"
      //       aria-controls="navbarTogglerDemo02"
      //       aria-expanded="false"
      //       aria-label="Toggle navigation"
      //     >
      //     <span className="navbar-toggler-icon"></span>
      //     </button>
      //     <a className="navbar-brand" onClick={this.handleRedirect}>
      //       <img
      //         className="logo"
      //         src={userType == "dj" ? "/img/logo.png" : "/img/logo-2.png"}
      //       />
      //       <span className="logo-txt">
      //         {userType == "dj" ? "Catch DJ" : "CatchApp"}
      //       </span>
      //       <span className="nightlife">Live Nightlife Experience</span>
      //       <span className="nightlife desktop-text">
      //         Live Nightlife Experience
      //       </span>
      //     </a>

      //     <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
      //       <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
      //         <li className="nav-item active"></li>
      //       </ul>
      //       <form className="form-inline my-2 my-lg-0">
      //         <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
      //           {this.state.isLoggedIn == 0 && (
      //             <li className="nav-item ">
      //               <a
      //                 className="nav-link about-us"
      //                 onClick={this.redirectToAbout}
      //               >
      //                 About Us
      //               </a>
      //             </li>
      //           )}

      //           {this.state.isLoggedIn == 1 && (
      //             <li className="nav-item">
      //               <a
      //                 className="nav-link profile-pic dj-img"
      //                 onClick={this.redirectToProfile}
      //               >
      //                 <span className="dj-pic">
      //                   <img
      //                     src={
      //                       userProfile
      //                         ? userProfile
      //                         : "https://nwsid.net/wp-content/uploads/2015/05/dummy-profile-pic-300x300.png"
      //                     }
      //                   />
      //                 </span>

      //                 <span className="userName">{userName}</span>
      //               </a>
      //             </li>
      //           )}

      //           {this.state.isLoggedIn == 1 && (
      //             <li className="nav-item ">
      //               <a
      //                 className="nav-link about-us"
      //                 onClick={this.redirectToAbout}
      //               >
      //                 About Us
      //               </a>
      //             </li>
      //           )}

      //           {this.state.isLoggedIn == 0 && (
      //             <li className="nav-item ">
      //               <a
      //                 className="nav-link login-button"
      //                 onClick={this.redirectToLogin}
      //               >
      //                 {"Login  "}
      //                 {/*<span className="bar">|</span>*/}
      //               </a>
      //             </li>
      //           )}
      //           {this.state.isLoggedIn == 1 && (
      //             <li className="nav-item">
      //               <a
      //                 className="nav-link login-button logout-btn"
      //                 onClick={this.logoutMe}
      //               >
      //                 {"Logout"}
      //                 {/*<i className="fa fa-cog" aria-hidden="true"></i>*/}
      //                 {/*<span className="bar">|</span>*/}
      //               </a>
      //             </li>
      //           )}
      //           {/*<li className="nav-item">
      //             <a
      //               className="nav-link map"
      //               onClick={this.props.showLocationModal}
      //             >
      //               <i className="fa fa-map-marker" aria-hidden="true"></i>
      //             </a>
      //           </li>*/}
      //           {this.state.isLoggedIn == 0 && (
      //             <li className="nav-item">
      //               <a className="nav-link dj-btn" onClick={this.redirectToDj}>
      //                 {"Are you a DJ?"}
      //               </a>
      //             </li>
      //           )}
      //         </ul>
      //       </form>
      //     </div>
      //   </nav>
      // </div>

      // New Header

      <header>
        <nav class="navbar navbar-expand-lg">
          <div className="container">
            <span class="navbar-brand">
              <img
                src={require("../../Assets/images/logo.svg").default}
                alt="logo"
                onClick={this.handleRedirect}
              />
            </span>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="d-flex align-items-center ml-auto mb-0">
                <li>
                <Link to="/">Teams</Link>
                </li>
                <li>
                <Link to="/">Resources</Link>
                </li>
                <li>
                  <div class="dropdown">
                    <button class="btn btn-transparent dropdown-toggle p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Services
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>Book section (table)</li>
                      <li>List venue</li>
                      <li>Promotions and ads</li>
                      <li>Partnership</li>
                    </div>
                  </div>
                </li>
                {this.state.isLoggedIn === 1 && (
                  <li onClick={this.redirectToProfile}>
                    <span className="dj-pic">
                      <img
                        src={
                          userProfile
                            ? userProfile
                            : "https://nwsid.net/wp-content/uploads/2015/05/dummy-profile-pic-300x300.png"
                        } alt=""
                      />
                    </span>

                    <span className="userName">{userName}</span>
                  </li>
                )}
                {this.state.isLoggedIn === 0 && (
                  <li onClick={this.redirectToDj}>
                    <Link to="/">Are you DJ?</Link>
                  </li>
                )}
                <li>
                </li>
                <li>
                  <button variant="unset" className="support_btn btn btn-unset">
                    Support
                  </button>
                  {this.state.isLoggedIn === 0 && (
                    <button variant="unset" className="login_btn btn btn-unset" onClick={this.redirectToLogin}>
                      Login
                    </button>
                  )}
                  {this.state.isLoggedIn === 1 && (
                    <button variant="unset" className="login_btn btn btn-unset" onClick={this.logoutMe}>
                      Logout
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <nav className="navbar navbar-expand-lg">
          <div className="container">
            <span classname="navbar-brand">
              <img
                src={require("../../Assets/images/logo.svg").default}
                alt="logo"
                onClick={this.handleRedirect}
              />
            </span>
            <div className="navbar-toggler" aria-controls="navbarScroll" />
            <div id="navbarScroll" className="collapse navbar-collapse">
              <nav className="ms-auto my-2 my-lg-0" navbarScroll>
                <ul className="d-flex align-items-center">
                  <li>
                    <div class="dropdown">
                      <button class="btn btn-transparent dropdown-toggle p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>Action</li>
                        <li>Another action</li>
                        <li>Something else here</li>
                      </div>
                    </div>
                  </li>
                  {this.state.isLoggedIn === 1 && (
                    <li onClick={this.redirectToProfile}>
                      <span className="dj-pic">
                        <img
                          src={
                            userProfile
                              ? userProfile
                              : "https://nwsid.net/wp-content/uploads/2015/05/dummy-profile-pic-300x300.png"
                          } alt=""
                        />
                      </span>

                      <span className="userName">{userName}</span>
                    </li>
                  )}
                  {this.state.isLoggedIn === 0 && (
                    <li onClick={this.redirectToDj}>
                      <Link to="/">Are you DJ?</Link>
                    </li>
                  )}
                  <li>
                  </li>
                  <li>
                    <button variant="unset" className="support_btn btn btn-unset">
                      Support
                    </button>
                    {this.state.isLoggedIn === 0 && (
                      <button variant="unset" className="login_btn btn btn-unset" onClick={this.redirectToLogin}>
                        Login
                      </button>
                    )}
                    {this.state.isLoggedIn === 1 && (
                      <button variant="unset" className="login_btn btn btn-unset" onClick={this.logoutMe}>
                        Logout
                      </button>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </nav> */}
      </header>
    );
  }
}
export default withRouter(Header);


