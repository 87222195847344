import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { withRouter } from "react-router";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
        },
        ipad: {
          breakpoint: { max: 767, min: 575 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1,
        },
      },
      venues: [
        {
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          title: "Score Launge",
        },
        {
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          title: "Tamzara",
        },
        {
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          title: "New Wave",
        },
        {
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          title: "Brew Brose",
        },
        {
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          title: "Score Launge",
        },
        {
          img: "https://upload.wikimedia.org/wikipedia/commons/3/32/Wikipedia_space_ibiza%2803%29.jpg",
          title: "Tamzara",
        },
      ],
    };
  }

  handleDetails = (clubId) => {
    return <div>{this.props.history.push(`/user-club/${clubId}`)}</div>;
  };

  render() {
    const venues = this.props.suggestionsList
      ? this.props.suggestionsList
      : this.props.liveClubs
      ? this.props.liveClubs
      : [];

    return (
      <section className="suggested_section">
        <div className="container">
          <div className="row">
            <div className="position-relative px-0 col col-md-12">
              <div className="mb-4">
                <div className="suggested_div">
                  <h2 className="sub_heading">
                    {this.props.title} venues
                    <img
                      src={require("../../Assets/images/thumb.svg").default}
                      alt="img"
                      className="ms-3"
                    />
                  </h2>
                  <p className="mt-3 mb-0">{this.props.description}</p>
                </div>
                <div className="mt-5 suggested_slider">
                  {venues.length > 0 && (
                    <Carousel
                      responsive={this.state.responsive}
                      swipeable={false}
                      draggable={false}
                      arrows={true}
                      showDots={true}
                      autoPlay={true}
                      rewind={true}
                      autoPlaySpeed={2000}
                      renderArrowsWhenDisabled="true"
                    >
                      {venues.map((v, index) => (
                        <div className="suggested_box" key={index}>
                          <div className="suggested_img">
                            <img
                              src={
                                v.profile_image ||
                                require("../../Assets/images/img1.png")
                              }
                              alt="img"
                              className="main_img1"
                            />
                            <div className="absolute">
                              <h5>{v.name}</h5>
                              <h6 onClick={() => this.handleDetails(v.club_id)}>
                                <img
                                  src={
                                    require("../../Assets/images/music.svg")
                                      .default
                                  }
                                  alt="img"
                                />
                                Listen Now
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  )}
                  {!venues.length && (
                    <div className="no_data text-center mt-3">
                      <img
                        src={require("../../Assets/images/no_data.png")}
                        alt=""
                      />
                      <h5 className="mt-0">No Data Found</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(index);
