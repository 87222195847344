import React, { Component } from "react";
import AgoraRTC from "agora-rtc-sdk";
let client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
let localStream;

const USER_ID = localStorage.getItem("userData") != null ? JSON.parse(localStorage.getItem("userData")).id : null;
const APP_ID = process.env.REACT_APP_AGORA_APP_ID;
let role = 'audience';
const STREAM_ID = Math.floor(Math.random() * 10000001);

export default class Call extends Component {

  state = {
    remoteStreams: [],
    streamStatus: "pause",
    remoteUsers: [],
    display: 'none',
    streamType: 'video'
  };

  componentDidMount(prevProps, prevState) {
    this.initClient();
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.channel !== this.props.channel && this.props.channel !== "") {
      this.joinChannel();
      //this.initLocalStream();
    }
  }

  initLocalStream = () => {

    localStream = AgoraRTC.createStream({
      streamID: STREAM_ID,
      audio: true,
      video: false
    });


    localStream.init(
      function () {
        console.log("getUserMedia successfully");
        localStream.play("agora_local");
      },
      function (err) {
        console.log("getUserMedia failed", err);
      }
    );
  };

  initClient = () => {
    client.init(
      APP_ID,
      function () {
        console.log("AgoraRTC client initialized");
      },
      function (err) {
        console.log("AgoraRTC client init failed", err);
      }
    );
  };

  subscribeToClient = () => {
    let me = this;

    client.on("stream-added", me.onStreamAdded);
    client.on("stream-subscribed", me.onRemoteClientAdded);

    client.on("user-joined", me.onRemoteClientAdded);

    client.on("user-published", me.onRemoteClientAdded);

    client.on("stream-removed", me.onStreamRemoved);

    client.on("peer-leave", me.onPeerLeave);

    client.on("user-left", function (evt) {
      client.leave();
      me.stopStreaming();
    });
  };

  onStreamAdded = evt => {
    let me = this;
    let stream = evt.stream;
    console.log("New stream added: " + stream.getId());
    me.setState(
      {
        remoteStreams: {
          //...me.state.remoteStream,
          [stream.getId()]: stream
        }
      },
      () => {
        // Subscribe after new remoteStreams state set to make sure
        // new stream dom el has been rendered for agora.io sdk to pick up
        client.subscribe(stream, function (err) {
          console.log("Subscribe stream failed", err);
        });

      }
    );
  };

  joinChannel = () => {
    let me = this;
    client.setClientRole('audience');
    client.join(
      me.props.token,
      me.props.channel,
      USER_ID,
      function (uid) {
        console.log("User " + uid + " join channel successfully as " + role);
        me.subscribeToClient();
      },
      function (err) {
        console.log("Join channel failed", err);

      }
    );
  };


  onRemoteClientAdded = evt => {
    let me = this;
    let stream = evt.stream;
    me.setState({
      streamType: !stream.video ? 'audio' : 'video'
    });

    me.state.remoteStreams[stream.getId()].play(
      "agora_remote " + stream.getId()
    );
    //me.startStreaming();

  };

  onStreamRemoved = evt => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;

      stream.stop();
      delete remoteStreams[streamId];

      me.setState({ remoteStreams });

      console.log("Remote stream is removed " + stream.getId());

      me.stopStreaming();
    }
  };

  onPeerLeave = evt => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;

      stream.stop();
      delete remoteStreams[streamId];

      me.setState({ remoteStreams });

      console.log(evt.uid + " leaved from this channel");

      me.stopStreaming();
    }
  };

  stopStreaming = () => {
    this.setState({
      streamStatus: 'close'
    });

    localStream && localStream.close();
    client && client.unpublish(localStream);
    client &&
      client.leave(
        () => {
          console.log("Client succeed to leave.");
          if (role == 'host') {
            window.location.href = '/dashboard';
          } else {
            window.location.href = '/';
          }
        },
        () => {
          console.log("Client failed to leave.");
        }
      );

    //this.localStream.disableVideo();

  };

  startStreaming = () => {
    this.setState({
      streamStatus: 'play',
      display: 'block'
    });

  };

  render() {
    return (
      <div>
        <div className="media-player">
          {this.state.streamStatus == 'pause' && (
            <>
              <img
                src={"/img/media-player1-min.png"}
                onClick={this.stopStreaming}
              />
              <div
                className="play button"
                onClick={this.startStreaming}
              >
                <span className={"inner-play"}>{"▶"}</span>
              </div>
            </>

          )}

        </div>



        {Object.keys(this.state.remoteStreams).map(key => {
          let stream = this.state.remoteStreams[key];
          let streamId = stream.getId();
          return (
            <div style={{ display: `${this.state.display}` }} className={this.state.streamType == 'audio' ? 'onlyaudio' : 'onlyvideo'}>
              <div
                className="play button"
                onClick={this.stopStreaming}

              >
                <span className={"inner-play"}>{"■"}</span>

              </div>
              <div
                key={streamId}
                id={`agora_remote ${streamId}`}
                className="agora_remote"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="https://gifdb.com/images/high/simple-audio-sound-wave-awolo4ekfaknri0n.webp" />
              </div>
            </div>
          );
        })}
      </div>

    );
  }
}