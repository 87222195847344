import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import Geocode from "react-geocode";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  fetchUserClubs,
  exportEmptyData,
  liveDJList,
  searchDj,
  topCities,
  citySearch,
  options,
} from "../../Actions/dashboardActions.js";
import Footer from "../HeadFoot/Footer";
import Hero from "../Hero";
import Service from "../Service";
import Header from "../HeadFoot/Header";
import Options from "../Options";
import Venues from "../Venues";
import Search from "../Search";
import LimittedOffer from "../LimittedOffer";

class NewDashboard extends Component {

  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    const userType = localStorage.getItem("user-type");
    const location = localStorage.getItem("clubLocation");
    localStorage.setItem("user-type", "user");
   
    this.state = {
      showLoader: false,
      responsive: {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
        },
        ipad: {
          breakpoint: { max: 767, min: 575 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1,
        },
      },
      venues: [],
      clubs: {},
      nearByList: [],
      suggestionsList: [],
      clubsList: [],
      term: "",
      logged_in: userData ? userData.logged_in : false,
      isLoggedIn: isLoggedIn ? isLoggedIn : 0,
      location: location ? location : "",
      userProfile: userData
        ? userData.profile_image.trim()
        : "https://nwsid.net/wp-content/uploads/2015/05/dummy-profile-pic.png",
      userProfilePicture: userData
        ? userData.profile_picture_url
        : "https://nwsid.net/wp-content/uploads/2015/05/dummy-profile-pic.png",
      djName: userData ? userData.name : "",
      userFirstName: userData ? userData.first_name : "",
      userLastName: userData ? userData.last_name : "",
      userName: "",
      userId: userData ? userData.user_id : 1,
      userType: userType ? userType : "",
      clubId: "",
      currentLocation: location ? location : "",
      authKey: userData ? userData.oauth_key : "",
      city: "",
      noClubs: {},
      visible: 2,
      totalRecords: "",
      limit: 10,
      offset: 0,
      showButton: false,
      searchDJ: false,
      liveClubs: [],
      famousCities: [],
      options: [],
      optionsData: [],
      selectedCity: location ? location : "",
      city_id: "",
      showMatch: false,
      showMe: false
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let data = position.coords;

        let lang = data.longitude;
        let lati = data.latitude;
        let placeData = {
          lang: lang,
          lati: lati,
        };

        Geocode.setApiKey("AIzaSyAOosj6Hg1fpweT-KC4FmbzOeZyuhzwdvw");
        //set response language. Defaults to english.
        Geocode.setLanguage("en");
        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("es");

        // Enable or disable logs. Its optional.
        Geocode.enableDebug();

        // Get address from latidude & longitude.
        Geocode.fromLatLng(lati, lang).then(
          (response) => {
            const address = response.results[3].formatted_address;
            //console.log(address);
            const data = address.split(",");
            //  / console.log(data);
            const city = data[data.length - 3];

            if (city == "" || city == undefined || city == null) {
              Geocode.fromLatLng(lati, lang).then((response) => {
                const address = response.results[2].formatted_address;
                //console.log(address);
                const data = address.split(",");
                //  / console.log(data);
                let city = data[data.length - 3];
                localStorage.setItem("clubLocation", city);
                localStorage.setItem("location", city);
                this.setState({ selectedCity: city, currentLocation: city });
              });
            } else {
              localStorage.setItem("clubLocation", city);
              localStorage.setItem("location", city);
              this.setState({ selectedCity: city, currentLocation: city });
            }
          },
          (error) => {
            console.error(error);
          }
        );

        localStorage.setItem("placeData", JSON.stringify(placeData));
      });
    } else {
      localStorage.setItem("clubLocation", "");
      console.error("Geolocation is not supported by this browser!");
    }

    const locationData = localStorage.getItem("clubLocation");
    if (
      this.state.selectedCity !== undefined ||
      this.state.selectedCity !== null ||
      this.state.selectedCity.trim() == ""
    ) {
      this.setState({ showMatch: true });
    }
    this.props.liveDJList();

    const city = "";

    /*if (this.state.userType == "dj") {
      if (
        this.state.logged_in !== true ||
        this.state.isLoggedIn !== 1 ||
        this.state.authKey == ""
      ) {
        window.location.href = `/`;
      }
    }*/
    if (this.state.userType == "dj") {
      if (
        this.state.logged_in == true ||
        this.state.isLoggedIn == 1 ||
        this.state.authKey !== ""
      ) {
        window.location.href = `/dashboard`;
      }
    }

    let formData = {
      // city_name: this.state.location ? this.state.location : locationData,
      city_name: this.state.selectedCity
        ? this.state.selectedCity
        : "Select Location",
      // limit: 3,
      offset: 0,
      searchDJ: false,
      user_id: this.state.userId,
    };

    this.setState({
      showLoader: true,
      currentLocation: this.state.location ? this.state.location : locationData,
      location: this.state.location ? this.state.location : locationData,
    });

    this.props.fetchUserClubs(formData);
    this.props.topCities();
    this.props.optionsEvents();
    /*if (
      (this.state.userType == "user" && this.state.logged_in == true) ||
      this.state.isLoggedIn == 1 ||
      this.state.authKey !== ""
    ) {
      this.setState({ showLoader: true });
      this.props.fetchUserClubs(formData);
    }*/
  }

  static getDerivedStateFromProps(props, state) {

    let returnState = {};
    // props.exportEmptyData();

    if (props.clubs !== undefined && props.clubs !== state.clubs) {
      returnState.clubs = props.clubs ? props.clubs : {};

      returnState.clubsList = [...state.clubsList, ...props.clubs.data];
      //returnState.clubsList = props.clubs.data ? props.clubs.data : [];
      returnState.offset = state.offset + 1;
      let suggestionsList = [];
      returnState.showButton = false;
      if (props.clubs && props.clubs.suggested_clubs) {
        let data = props.clubs.suggested_clubs;
        data.map((obj, id) => {
          // if (id < 6) {
            suggestionsList.push(obj);
          // }
        });
        returnState.visible = 2;
      }
      returnState.suggestionsList = suggestionsList ? suggestionsList : [];
      returnState.nearByList = props.clubs.nearby_clubs
        ? props.clubs.nearby_clubs
        : [];

      returnState.showMe = false;
      returnState.totalRecords = props.clubs.paginator
        ? props.clubs.paginator.totalRecords
        : 0;
      returnState.limit = props.clubs.paginator
        ? props.clubs.paginator.limit
        : 3;
      returnState.offset = props.clubs.paginator
        ? props.clubs.paginator.offset
        : 0;
      returnState.showButton = false;
      returnState.showLoader = false;
      //returnState.location = props.clubs.location;
      //returnState.currentLocation = props.clubs.location;
    }
    if (
      props.noClubs !== undefined &&
      props.noClubs.error == true &&
      props.noClubs !== state.noClubs
    ) {
      returnState.showMe = false;
      returnState.clubsList = [];
      let suggestionsList = [];
      if (props.noClubs && props.noClubs.suggested_clubs) {
        let data = props.noClubs.suggested_clubs;
        data.map((obj, id) => {
          // if (id < 6) {
            suggestionsList.push(obj);
          // }
        });
      }
      returnState.showButton = false;
      returnState.suggestionsList = suggestionsList ? suggestionsList : [];
      returnState.nearByList = props.noClubs.nearby_clubs
        ? props.noClubs.nearby_clubs
        : [];
      returnState.showLoader = false;
    }

    if (props.liveClubs !== undefined && props.liveClubs !== state.liveClubs) {
      let liveList = [];
      if (props.liveClubs && props.liveClubs.data.length > 0) {
        let data = props.liveClubs.data;
        data.map((obj, id) => {
          if (id < 6) {
            liveList.push(obj);
          }
        });
      }
      returnState.liveClubs = liveList ? liveList : [];
    }
    if (
      props.famousCities !== undefined &&
      props.famousCities.error == false &&
      props.famousCities !== state.famousCities
    ) {
      returnState.famousCities = props.famousCities
        ? props.famousCities.data
        : [];
    }
    if (
      props.searchCity !== undefined &&
      props.searchCity !== state.searchCity &&
      props.searchCity.error == false
    ) {
      returnState.options = props.searchCity ? props.searchCity.data : [];
    }

    if(props.optionsData !== undefined &&
      props.optionsData !== state.optionsData &&
      props.optionsData.error == false)
    {
      returnState.optionsData = props.optionsData ? props.optionsData.data : [];
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    //console.log(target.name);
    this.setState({
      [event.target.name]: value,
    });
  };

  clubDetails = (clubId) => {
    if (clubId == undefined || clubId == "") {
      return;
    }

    return <div>{this.props.history.push(`/user-club/${clubId}`)}</div>;
  };

  showLocationModal = () => {
    this.setState({
      showMe: true,
      showMatch: true,
      city: "",
      cityError: "",
    });
  };

  dismissModal = () => {
    this.setState({ showMe: false, showMatch: true, city: "", cityError: "" });
  };

  aClubForMe = () => {
    if (
      this.state.city == undefined ||
      this.state.city == null ||
      this.state.city == "" ||
      this.state.city.trim() == ""
    ) {
      this.setState({ cityError: "fieldError", showMatch: true });
      return;
    }

    let formData = {
      city_name: this.state.city,
      user_id: this.state.userId,
      limit: 3,
      offset: 0,
    };
    localStorage.setItem("clubLocation", this.state.city);
    this.setState({
      showLoader: true,
      currentLocation: this.state.city,
      selectedCity: this.state.city,
      clubsList: [],
      searchDJ: false,
      showMatch: true,
    });
    this.props.fetchUserClubs(formData);
  };

  loadMore = () => {
    const location = localStorage.getItem("clubLocation");
    let formData;
    if (this.state.clubsList.length !== this.state.totalRecords) {
      formData = {
        /*city_name: this.state.currentLocation
          ? this.state.currentLocation
          : "Select Location",*/
        city_name: this.state.selectedCity
          ? this.state.selectedCity
          : location
          ? location
          : "india",
        user_id: this.state.userId,
        offset: this.state.offset + 1,
        limit: this.state.limit,
      };
      this.setState({ showButton: true });
      this.props.fetchUserClubs(formData);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let formData = {
      city_name: this.state.selectedCity ? this.state.selectedCity : "India",
      text: this.state.term,
      // user_id: this.state.userId,
      search: true,
      // country: this.state.currentLocation,
    };
    this.setState({ showLoader: true, searchDJ: true, clubsList: [] });
    if (this.state.term) {
      this.props.searchDj(formData);
    } else {
      formData = {
        city_name: this.state.selectedCity ? this.state.selectedCity : "India",
        user_id: this.state.userId,
        limit: 3,
        offset: 0,
      };
      this.setState({ searchDJ: false, clubsList: [] });
      this.props.fetchUserClubs(formData);
    }
  };

  handleSelectChange = (mode, event) => {
    this.props.exportEmptyData();
    const target = event.target;
    let value = target.value;
    let name = event.target.name;

    this.setState({ selectedCity: value });
    if (typeof value === "string") {
      if (value.length >= 2) {
        value = value.trim();
        this.setState({ showMatch: false, options: [] });
        this.props.citySearch({ text: value });
      }
    }
  };

  handleOnFocus = (mode, index, event) => {
    this.props.exportEmptyData();
    //console.log(event.target.value);
    this.setState({ [mode]: true });
    if (typeof value === "string") {
      if (this.state.selectedCity.length < 1) {
        //this.props.citySearch({ text: this.state.selectedCity });
        this.setState({ selectedCity: "", options: [], showMatch: true });
      }
    }
  };

  selectCity = (event) => {
    console.log('event', event);
    let formData = {};
    const target = event.target;
    let value = target.value;
    let name = event.target.name;
    let mode = event.currentTarget.dataset.mode;
    let id = event.currentTarget.dataset.id;
    let pname = event.currentTarget.dataset.pname;
    formData = {
      city_name: pname,
      user_id: this.state.userId,
      location: this.state.selectedCity,
      offset: 0,
    };
    this.setState({
      city_id: id,
      city_name: pname,
      selectedCity: pname,
      currentLocation: pname,
      options: [],
      showMatch: true,
      showLoader: true,
      clubsList: [],
    });
    localStorage.setItem("clubLocation", pname);
    this.props.fetchUserClubs(formData);
  };

  topCities = (city) => {
    this.setState({
      currentLocation: city ? city : "Select Location",
      selectedCity: city ? city : "Select Location",
      showMatch: true,
      showLoader: true,
      clubsList: [],
      options: [],
    });

    let formData = {
      city_name: city ? city : "Select Location",
      searchDJ: false,
      //user_id: this.state.userId,
      //location: city ? city : "Select Location",
      // limit: 3,
      offset: 0,
    };

    localStorage.setItem("clubLocation", city);
    this.props.fetchUserClubs(formData);
  };

  render() {
 
    // console.log( this.props.optionsData);

    let lastName;
    if (
      this.state.userLastName == null ||
      this.state.userLastName == undefined ||
      this.state.userLastName.trim() == ""
    ) {
      lastName = "";
    } else {
      lastName = this.state.userLastName;
    }
    const userName = this.state.userFirstName + " " + lastName;
    // console.log(this.state.liveClubs);
    var options = [];

    if (this.state.options != undefined && this.state.options.length > 0) {
      options = this.state.options.map((obj, idx) => {
        return { value: obj.id, label: obj.name };
      });
    }
    

    return (
      <LoadingOverlay
        active={this.state.showLoader}
        spinner={<ScaleLoader color={"#fb556b"} />}
        text={"Loading"}
      >
        <div>
          <Header 
            userProfile={
              this.state.userProfile
                ? this.state.userProfile
                : this.state.userProfilePicture
            }
            userName={this.state.djName ? this.state.djName : userName}
            showLocationModal={() => {
              this.showLocationModal();
            }}
          />
          <Hero />
          <Service />
          <Options optionsData={this.state.optionsData} />
          <Search 
            options={this.state.options}
            term={this.state.term}
            dismissModal={this.dismissModal}
            showModal={this.state.showMe}
            showMatch={this.state.showMatch}
            famousCities={this.state.famousCities}
            currentLocation={this.state.currentLocation}
            showLocationModal={this.showLocationModal}
            selectedCity={this.state.selectedCity}
            selectCity={this.selectCity}
            topCities={this.topCities}
            handleSelectChange={this.handleSelectChange}
            handleOnFocus={this.handleOnFocus}
            handleSubmit={this.handleSubmit} 
            handleInputChange={this.handleInputChange}
            clubsList={this.state.clubsList}
            userType={this.state.userType}
            clubDetails={this.clubDetails}
           />
          <Venues 
            title="Suggested" 
            description="Find out what’s happening in your current city" 
            suggestionsList={this.state.suggestionsList}
          />
          <LimittedOffer />
          <Venues 
            title="Trending" 
            description="Connect with fun venues in different cities around the globe." 
            liveClubs={this.state.liveClubs}
          />
          <Footer />
        </div>
      </LoadingOverlay>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};
  if (state.DashboardReducer.action === "USER_CLUB_LIST") {
    if (state.DashboardReducer.data.error == true) {
      returnState.noClubs = state.DashboardReducer.data;
    } else {
      returnState.clubs = state.DashboardReducer.data;
    }
  }
  if (state.DashboardReducer.action === "SEARCH_CLUBS") {
    if (state.DashboardReducer.data.error == true) {
      //returnState.noClubs = state.DashboardReducer.data;
    } else {
      console.log('clubs', state.DashboardReducer.data);
      returnState.clubs = state.DashboardReducer.data;
    }
  }
  if (state.DashboardReducer.action === "LIVE_DJ") {
    if (state.DashboardReducer.data.error == true) {
      //returnState.noClubs = state.DashboardReducer.data;
    } else {
      returnState.liveClubs = state.DashboardReducer.data;
    }
  }
  if (state.DashboardReducer.action === "TOP_CITIES") {
    if (state.DashboardReducer.data.error == true) {
      //returnState.noClubs = state.DashboardReducer.data;
    } else {
      returnState.famousCities = state.DashboardReducer.data;
      
    }
  }
  if (state.DashboardReducer.action === "SEARCH_CITY") {
    if (state.DashboardReducer.data.error == true) {
      //returnState.noClubs = state.DashboardReducer.data;
    } else {
      returnState.searchCity = state.DashboardReducer.data;
    }
  }
  if (state.DashboardReducer.action === "GET_EVENTS") {

    if (state.DashboardReducer.data.error == true) {
      //returnState.options = state.DashboardReducer.data;
    } else {
      returnState.optionsData = state?.DashboardReducer?.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserClubs: fetchUserClubs,
      exportEmptyData: exportEmptyData,
      searchDj: searchDj,
      liveDJList: liveDJList,
      topCities: topCities,
      citySearch: citySearch,
      optionsEvents: options
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard);