import React, { Component } from 'react'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
        },
        ipad: {
          breakpoint: { max: 767, min: 575 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1,
        },
      },

    };
  }

  render() {
    console.log(this.props.clubsList);

    return (
      <section className="search_venues">
        <div className="container">
          <div className="row">
            <div className="col col-md-12">
              <form>
                <div className='form_bg'>
                  <div className="flex1">
                    <span>
                      <img
                        src={
                          require("../../Assets/images/location.svg").default
                        }
                        alt="location"
                      />
                    </span>
                    <input type="text"
                      className="form-control"
                      placeholder='Search Location'
                      value={this.props.selectedCity ? this.props.selectedCity : this.props.currentLocation
                        ? this.props.currentLocation
                        : "Select Location"}
                      onClick={this.props.showLocationModal}
                    />
                  </div>
                  <div className="flex2">
                    <span>
                      <img
                        src={
                          require("../../Assets/images/location.svg").default
                        }
                        alt="location"
                      />
                    </span>
                    <div class="form-group mb-0" controlId="formBasicPassword">
                      <input
                        type="text"
                        placeholder="Search venue"
                        className="form-control"
                        value={this.props.term}
                        onChange={(e) => {
                          this.props.handleInputChange(e);
                        }}
                        onKeyDown={e => {
                          if(e.key === 'Enter'){
                            console.log('Enter');
                            this.props.handleSubmit(e);
                          }
                        }}
                        name="term"
                      />
                    </div>
                  </div>
                </div>
                {
                  (this.props.clubsList && this.props.clubsList.length > 0) &&
                  <div className='row'>
                    <div className='col-md-12 px-0'>
                      <div className="suggested_section position-relative px-0">
                        <div className="mb-4">
                          <div className="suggested_div">
                            <h2 className="sub_heading text-white">
                              Clubs nearby you
                            </h2>
                          </div>
                          <div className="mt-5 suggested_slider">
                            <Carousel
                              responsive={this.state.responsive}
                              swipeable={false}
                              draggable={false}
                              arrows={true}
                              showDots={true}
                              autoPlay={true}
                              rewind={true}
                              autoPlaySpeed={2000}
                              renderArrowsWhenDisabled="true"
                            >
                              {
                                this.props.clubsList.map((club, index) => (
                                  <div 
                                    className="suggested_box" 
                                    key={index}
                                    onClick={
                                      this.props.userType === "user"
                                        ? this.props.clubDetails.bind(this, club.club_id)
                                        : this.props.clubDetails.bind(this, club.id)
                                    }
                                    >
                                    <div className="suggested_img">
                                      <img
                                        src={club.profile_image || require("../../Assets/images/img4.png")}
                                        alt="img"
                                        className="main_img1"
                                      />
                                      <div className="absolute">
                                        <h5>{club.name}</h5>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>

        {this.props.showModal && (
          <div
            className="modal fade"
            id="addclub"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.dismissModal}
                >
                  <span aria-hidden="true">
                    <img src="img/close.png" alt="" />
                  </span>
                </button>
                <div className="modal-header text-center">
                  <h5
                    className="modal-title new-club"
                    id="exampleModalLabel"
                  >
                    Choose Your City
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="display-block type-something city-border">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      className={"form-control"}
                      name="selectedCity"
                      value={this.props.selectedCity}
                      autoComplete="off"
                      onChange={this.props.handleSelectChange.bind(
                        this,
                        "selectedCity"
                      )}
                      //onBlur={this.handleOnBlur.bind(this, 'bogo_product_focus')}
                      onFocus={this.props.handleOnFocus.bind(
                        this,
                        "selectedCity_focus"
                      )}
                      placeholder="Search City"
                      type="text"
                    />
                    {this.props.selectedCity &&
                      this.props.selectedCity !== "" &&
                      this.props.selectedCity.length > 1 &&
                      !this.props.showMatch && (
                        <ul className={"search-dropdown"}>
                          {this.props.options.length > 0 ? (
                            this.props.options.map((obj, idx) => {
                              console.log(obj);
                              return (
                                <li
                                  key={"selectedCity-" + idx}
                                  data-id={obj.id}
                                  data-mode={"selectedCity"}
                                  data-pname={obj.name}
                                  onClick={(e) => this.props.selectCity(e)}
                                >
                                  <span>{obj.name}</span>
                                </li>
                              );
                            })
                          ) : (
                            <li
                              className={
                                this.props.options.length === 0 &&
                                  this.props.selectedCity.length > 1
                                  ? "no-match"
                                  : "no-display"
                              }
                              key={"selectedCity-norecord"}
                              data-id={0}
                              data-mode={"selectedCity"}
                              data-pname={"city_match_not_found"}
                              data-index={-1}
                            >
                              <span>{"No match found!"}</span>
                            </li>
                          )}
                        </ul>
                      )}
                  </div>
                  <p className={"modal-line"}></p>
                  <div className="d-block another-city">
                    <h6>Choose Other City</h6>
                    <ul>
                      {this.props.famousCities &&
                        this.props.famousCities.length > 0 &&
                        this.props.famousCities.map((obj, id) => {
                          return (
                            <li
                              key={id}
                              onClick={() => this.props.topCities(obj.name)}
                            >
                              {obj.name}
                            </li>
                          );
                        })}
                    </ul>

                    <div></div>
                  </div>
                </div>
                <div className="modal-footer modal-button">
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    )
  }
}
