import React, { Component } from 'react'

export default class index extends Component {
  render() {
    return (
      <section className="why_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-sm-8 m-auto text-center">
              <div className="d-inline-block text-center why_img_div">
                <img
                  src={require("../../Assets/images/why_img.png")}
                  alt="img"
                  className="rotate-full" style={{ "position": "relative", "zIndex": "1024" }}
                />
                <img
                  src={require("../../Assets/images/red_dot_bg.svg").default}
                  alt="img"
                  className="dot_image" style={{ "zIndex": "1" }}
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="padding_left">
                <h2 className="sub_heading">Why CatchApp?</h2>
                <p>
                  {/* CatchApp is a live streaming platform for nightlife
                  industry. We connect online audiences with nightlife
                  experience in real-time Users can be able to listen and
                  know what's happening in clubs/venues before there go out.
                  Nightlife industry businesses can be able to attract
                  online audiences through live streaming music on CatchApp
                  and real-time updates on specials to their target market.
                  Through CatchApp stories and insights updates (traffic and
                  gender) users can get hype to have fun either virtually or
                  physically. Dj are able to attract real-time fan-base into
                  their venues and also virtual audiences streaming around
                  the world. */}

                  Get real-time insights on what's happening at different venues in different cities to save time and money. Make smart choices on how best to enjoy nightlife (out-tings) experiences and take advantage of exclusive offers and discounts at various venues.
                </p>
                <button variant="unset" className="btn main_btn">
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
