import React, { Component } from 'react'

export default class LimittedOffer extends Component {
  render(){
    return (
      <section className="limited_offer">
      <div className="container">
        <div className="row">
          <div className="col col-md-12">
            <h3>
              Limited Offer
              <img
                src={require("../../Assets/images/offer.svg").default}
                alt="offer"
                className="ms-3"
              />
            </h3>
            <h5 className="mt-4">
              Get 40% off deals on your next night out  <br /> by booking through the app
            </h5>
            <button variant="unset" className="main_btn btn btn-unset">
              Grab Now
              <img
                src={require("../../Assets/images/btn_arrow.svg").default}
                alt="img"
                className="ms-3"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
    )
  }
}
